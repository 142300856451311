import React from "react";
import Contact from "./Components/Contact";
import Home from "./Components/Home";
import Clients from "./Components/Clients";
import Maintenance from "./Components/Maintenance";
import Services from "./Components/Services";
import Naav from "./Components/Naav";
import "./CSS/Style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css";
import { Route, Routes } from "react-router-dom";
const App = () => {
  return (
    <div>
      <Naav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/maintenance" element={<Maintenance />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </div>
  );
};

export default App;
