import React from "react";

import logo1 from "../Photos/OIP (5).jpg";
import logo2 from "../Photos/laptop2.jpg";
import logo3 from "../Photos/laptop3.jpg";
import logo4 from "../Photos/laptop5.jpg";
import logo5 from "../Photos/WhatsApp Image 2023-02-01 at 7.55.53 PM.jpeg";
import logo6 from "../Photos/laptop1.jpg";
import logo7 from "../Photos/headimage-005.png";
import logo8 from "../Photos/headimage-006.png";
import logo10 from "../Photos/headimage-007.png";
import logo11 from "../Photos/headimage-008.png";
import logo12 from "../Photos/headimage-009.png";
import logo13 from "../Photos/OIP (6).jpg";
import logo14 from "../Photos/OIP (7).jpg";
import logo15 from "../Photos/OIP (8).jpg";
import logo16 from "../Photos/OIP (9).jpg";
import { AnimationOnScroll } from "react-animation-on-scroll";
const ThirdHome = () => {
  return (
    <div>
      <AnimationOnScroll animateIn="animate__slideInLeft">
        <div>
          <div className="d-flex flex-column bd-highlight mb-3  head1 img1">
            <h1 className="fw-bolder ms-3">COMPUTERS & LAPTOPS</h1>
            <br />
            <h3 className="cns ms-3">
              IBM, HP COMPAQ, DELL, ACER Pc's & Notebooks.
            </h3>
          </div>

          <div className="row">
            <div className="col-1 hello"></div>
            <div className="first col-11">
              <p className=" fs-5  container parg">
                Computers are used as control systems for a wide variety of
                industrial and consumer devices. This includes simple
                special-purpose devices like microwave ovens, remote controls,
                factory devices such as industrial robots , computer-aided
                design, and also general-purpose devices like personal computers
                and mobile devices such as smartphones. The Internet is run on
                computers and it connects hundreds of millions of other
                computers and their users.
              </p>
              <div className=" container heading3">
                <div className="d-flex flex-column bd-highlight mb-3 justify-content-center align-items-center">
                  <img src={logo1} alt="Not Supported" className="img5 mt-3 " />
                  <img src={logo5} alt="Not Supported" className="img7" />
                  <p className=" fs-5  container parg">
                    <span className=" cns fw-bold">CNS</span> has a maintenance
                    center for all computers and laptops at the highest level
                    and provide appropriate spare parts.
                  </p>
                </div>

                <div className="">
                  <div className="  logos2 mb-3 ">
                    <img src={logo2} alt="Not Supported" className="img3  " />{" "}
                    <img src={logo3} alt="Not Supported" className="img3 " />
                    <img
                      src={logo4}
                      alt="Not Supported"
                      className="img3 "
                    />{" "}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row bd-highlight mb-3 head5">
                <img src={logo6} alt="Not Supported" className="img5 " />
              </div>
            </div>
          </div>
        </div>
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__slideInLeft">
        <div>
          <div className="d-flex flex-row bd-highlight mb-3">
            <img src={logo7} alt="Not Supported" className="img1" />
          </div>

          <div className="row">
            <div className="col-1 hello"></div>
            <div className="first col-11 ">
              <p className=" fs-5  container parg">
                The Company's policy is to select only the highest quality
                Equipment for any specific requirement.{" "}
                <span className=" cns fw-bold">CNS</span> Company are Accredited
                partners with the suppliers of the most Advanced Closed Circuit
                Camera equipment in the worldand install, Supply and com­mission
                cctv system for wide Variety of clients.
              </p>
              <img src={logo8} alt="Not Supported" className="img1" />
            </div>
          </div>
        </div>
      </AnimationOnScroll>
      <AnimationOnScroll animateIn="animate__slideInLeft">
        <div>
          <div className="d-flex flex-row bd-highlight mb-3">
            <img src={logo10} alt="Not Supported" className="img1" />
          </div>

          <div className="row">
            <div className="col-1 hello"></div>
            <div className="first col-11 ">
              <p className=" fs-5  container parg">
                Security Metal Detectors Audio warning light degree sensitivity
                - the plate to determine the height of the place metal body
                vertically-Indoor-outdoor.
              </p>
              <img src={logo11} alt="Not Supported" className="img1" />
            </div>
          </div>
          <div className="d-flex flex-row bd-highlight mb-3">
            <img src={logo12} alt="Not Supported" className="img1" />
          </div>
          <div className="row">
            <div className="col-1 hello"></div>
            <div className="first col-11 ">
              <p className=" fs-5  container parg">
                Access control is a way of limiting access to a system or to
                physical or virtual resourc­es. In computing, access control is
                a process by which users are granted access and certain
                privileges to systems, resources or information. In access
                control systems, users must present credentials before they can
                be granted access. In physical systems, these credentials may
                come in many forms, but credentials that can't be transferred
                provide the most security.
              </p>
              <div className="">
                <div className="logos2 mb-3">
                  <img src={logo13} alt="Not Supported" className="img3" />
                  <img src={logo14} alt="Not Supported" className="img3" />
                  <img src={logo15} alt="Not Supported" className="img3" />
                  <img src={logo16} alt="Not Supported" className="img3" />
                </div>
              </div>
            </div>
          </div>
          <div className="space"></div>
        </div>
      </AnimationOnScroll>
    </div>
  );
};

export default ThirdHome;
