import React from "react";
import logO from "../Photos/image-000.png";
import logo from "../Photos/headimage-001.png";
import logo2 from "../Photos/Dell logo.jpg";
import logo3 from "../Photos/techPhotos/Access-Control-1.jpg";
import logo4 from "../Photos/techPhotos/APEXCOMPUTERS-Srinagar-JK.jpeg";
import logo5 from "../Photos/techPhotos/istockphoto-928791064-612x612.jpg";
import logo6 from "../Photos/techPhotos/modern-security-cctv-camera-with-motion-sensor-surveillance-security-concept-3d-render_37817-675.jpg";
import logo7 from "../Photos/techPhotos/politique-de-la-protection-des-donnees-personnelles-vacataires-1600x640.jpg";
import logo8 from "../Photos/cisco logo.png";
import logo9 from "../Photos/lenovo logo.png";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
const FirstHome = () => {
  return (
    <div>
      <div className="first ">
        <img src={logO} alt="Not Supported" className="img1 " />
      </div>
      <AnimationOnScroll animateIn="animate__slideInLeft">
        <div>
          <img src={logo} alt="Not Supported" className="img1" />
          <div className="headSecond row ">
            <div className="col-1 hello"></div>
            <div className="col-11">
              <p className=" fs-5 text-start container ">
                <span className=" cns fw-bolder">CNS</span> has the pleasure to
                introduce itself as a company specialized in all fields in the
                computer industry.
              </p>

              <div>
                <h3 className="srvs fw-bolder fs-5 text-center ">
                  Our Services include
                </h3>
              </div>
              <h5 className="container">Hardware commerce:</h5>
              <ul className="container">
                <li className="one">
                  {" "}
                  <h5>IBM,HP COMPAQ, DELL, ACER Pc's & Notebooks.</h5>
                </li>
                <li className="one">
                  {" "}
                  <h5>
                    Compatable Pc's and its components, Printers and toners.
                  </h5>
                </li>
                <li className="one">
                  {" "}
                  <h5>CCTV System.</h5>
                </li>
                <li className="one">
                  {" "}
                  <h5>Metal Detectors.</h5>
                </li>
                <li className="one">
                  {" "}
                  <h5>Access control solution & Fingerprint devices.</h5>
                </li>
                <li className="one">
                  {" "}
                  <h5>Maintenance.</h5>
                </li>
                <li className="one">
                  {" "}
                  <h5>Networks.</h5>
                </li>
                <li className="one">
                  {" "}
                  <h5>IT Security.</h5>
                </li>
                <li className="one">
                  {" "}
                  <h5>We are proudly partners of :</h5>
                </li>
              </ul>
            </div>
          </div>

          <div className="logos1 ">
            <img src={logo2} alt="Not Supported" className="img3  " />{" "}
            <img src={logo8} alt="Not Supported" className="img3 " />
            <img src={logo9} alt="Not Supported" className="img3 " />
          </div>
          <div className="row ">
            <div className="col-1 hello"></div>
            <div className="col-11">
              <p className=" fs-5  container parg">
                <span className=" cns fw-bold">CNS</span> has a complete highly
                skill IT staff who mani­fests excellence in services and are
                always meeting our clients demand and needs on time. We are
                available to respond to any inquiry , Our goal is to achieve
                client satisfaction and all calls and inquiries are welcome at
                all times.{" "}
              </p>
            </div>
          </div>
          <div className="row ">
            <div className="col-1 hello"></div>
            <div className="col-11">
              <div className="  logos">
                <img src={logo3} alt="Not Supported" className="img3 mb-4  " />{" "}
                <img src={logo4} alt="Not Supported" className="img3 " />
                <img src={logo5} alt="Not Supported" className="img3 " />
                <img src={logo6} alt="Not Supported" className="img3 " />
                <img src={logo7} alt="Not Supported" className="img3 " />
              </div>
            </div>
          </div>

          <div className="space"></div>
        </div>
      </AnimationOnScroll>
    </div>
  );
};

export default FirstHome;
