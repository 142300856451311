import React from "react";
import logo from "../Photos/image-010.png";
import { AnimationOnScroll } from "react-animation-on-scroll";
const EigthHome = () => {
  return (
    <AnimationOnScroll animateIn="animate__slideInLeft">
      <div className="first">
        <img src={logo} alt="Not Supported" className="img1" />
        <div className="d-flex flex-column bd-highlight mb-3 justify-content-center align-items-center ms-4">
          <p className="shadow-lg p-3 mb-5 bg-body rounded box1 text-center mt-3 parg">
            <span className="fw-bolder">Head Office </span> Secretary@cns-eg.com{" "}
            <br /> <span className="fw-bolder"> Chairman:</span>{" "}
            Elham.amin@cns-eg.com <br />
            <span className="fw-bolder"> Executive Director: </span>
            Soha.issa@cns-eg.com <br />{" "}
            <span className="fw-bolder">Financial manager: </span>{" "}
            Eyad.elsharif@cns-eg.com
            <br /> <span className="fw-bolder">Address : </span> 37 Giza St. in-
            Giza, Egypt <br />
            +202-35704411 <br /> +202-35704488 <br /> +201001142010
          </p>
        </div>
      </div>
    </AnimationOnScroll>
  );
};

export default EigthHome;
