import React from "react";
import logo from "../Photos/image-008.png";
import logo1 from "../Photos/RAMW.png";
import logo2 from "../Photos/Osn.png";
import logo3 from "../Photos/Tarik_nour1.jpg";
import logo4 from "../Photos/epic.png";
import logo5 from "../Photos/bank markzy.jpg";
import logo6 from "../Photos/Arafa.jpg";
import logo7 from "../Photos/maven.png";
import logo8 from "../Photos/Otis-Logo.jpg";
import logo9 from "../Photos/rotana.png";
import logo10 from "../Photos/scan.png";
import logo11 from "../Photos/sodic.png";
import { AnimationOnScroll } from "react-animation-on-scroll";
const SeventhHome = () => {
  return (
    <AnimationOnScroll animateIn="animate__slideInLeft">
      <div>
        <div className="first">
          <img src={logo} alt="Not Supported" className="img1" />
        </div>
        <div className="row">
          <div className="col-1 hello"></div>
          <div className="first col-11 ">
            <h4 className="cns mt-3 ">
              We have the pleasure to introduce a list of some of our clients:
            </h4>
          </div>
          <div className="row">
            <div className="col-1 hello"></div>
            <div className="first col-11 ">
              <div className="">
                <div className="logos4 mb-3">
                  <div className="logos3 mb-3">
                    <img src={logo1} alt="Not Supported" className="img3" />

                    <h4 className="shadow-lg p-3 mb-5 bg-body rounded box text-center">
                      RAMW
                    </h4>
                  </div>
                  <div className="logos3 mb-3">
                    <img src={logo2} alt="Not Supported" className="img3 " />

                    <h4 className="shadow-lg p-3 mb-5 bg-body rounded box text-center">
                      OSN
                    </h4>
                  </div>
                  <div className="logos3 mb-3">
                    <img src={logo3} alt="Not Supported" className="img3 " />

                    <h4 className="shadow-lg p-3 mb-5 bg-body rounded box text-center">
                      TNC
                    </h4>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="logos4 mb-3">
                  <div className="logos3 mb-3">
                    <img src={logo4} alt="Not Supported" className="img3" />

                    <h4 className="shadow-lg p-3 mb-5 bg-body rounded box text-center">
                      EPIC
                    </h4>
                  </div>
                  <div className="logos3 mb-3">
                    <img src={logo5} alt="Not Supported" className="img3" />

                    <h4 className="shadow-lg p-3 mb-5 bg-body rounded  text-center box ">
                      المعهد
                      <br /> المصرفي المصري
                    </h4>
                  </div>
                  <div className="logos3 mb-3">
                    <img src={logo6} alt="Not Supported" className="img3" />

                    <h4 className="shadow-lg p-3 mb-5 bg-body rounded  text-center box">
                      ARAFA GROUP
                    </h4>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="logos4 mb-3">
                  <div className="logos3 mb-3">
                    <img src={logo7} alt="Not Supported" className="img3" />

                    <h4 className="shadow-lg p-3 mb-5 bg-body rounded text-center box ">
                      MAVEN <br /> DEVELOPMENT
                    </h4>
                  </div>
                  <div className="logos3 mb-3">
                    <img src={logo8} alt="Not Supported" className="img3" />

                    <h4 className="shadow-lg p-3 mb-5 bg-body rounded  text-center box ">
                      OTIS
                    </h4>
                  </div>
                  <div className="logos3 mb-3">
                    <img src={logo9} alt="Not Supported" className="img3" />

                    <h4 className="shadow-lg p-3 mb-5 bg-body rounded  text-center box ">
                      ROTANA
                    </h4>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="logos4 mb-3">
                  <div className="logos3 mb-3">
                    <img src={logo10} alt="Not Supported" className="img3" />

                    <h4 className="shadow-lg p-3 mb-5 bg-body rounded  text-center box ">
                      NILE SCAN & LABS
                    </h4>
                  </div>
                  <div className="logos3 mb-3">
                    <img src={logo11} alt="Not Supported" className="img3" />

                    <h4 className="shadow-lg p-3 mb-5 bg-body rounded  text-center box ">
                      SODIC
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AnimationOnScroll>
  );
};

export default SeventhHome;
