import React from "react";
import logo from "../Photos/headimage-010.png";
import logo3 from "../Photos/OIP (10).jpg";
import logo4 from "../Photos/OIP (11).jpg";
import logo5 from "../Photos/OIP (12).jpg";
import logo6 from "../Photos/OIP (13).jpg";
import logo7 from "../Photos/headimage-011.png";
import logo8 from "../Photos/OIP (14).jpg";
import logo9 from "../Photos/OIP (15).jpg";
import logo10 from "../Photos/OIP (16).jpg";
import logo11 from "../Photos/OIP (17).jpg";
import { AnimationOnScroll } from "react-animation-on-scroll";
const SixthHome = () => {
  return (
    <AnimationOnScroll animateIn="animate__slideInLeft">
      <div>
        <div className="first">
          <img src={logo} alt="Not Supported" className="img1" />
        </div>
        <div className="row">
          <div className="col-1 hello"></div>
          <div className="first col-11 ">
            <p className=" fs-5  container parg">
              All <span className=" cns fw-bold">CNS</span> products are
              generally designed so that they faciltate work and save time.
              However problems can still occur unexpectedly. CNS provides
              immediate technical assistance.{" "}
              <span className=" cns fw-bold">
                CNS's Team is happy to help: <br /> <br />
              </span>{" "}
              Installation, startup, put into operation, problem specification
              and resolution as well as aftersales services . Ranging from
              online condition monitoring system, Problem specification, and
              solutions in mobile vibration measurement devices (hand-held
              measuring devices). Problem specification and resolution for laser
              align­ment devices and geometric alignment system, Problem
              specification and resolution for all condition monitoring and
              alignment software.
            </p>
            <div className="">
              <div className="logos2 mb-3">
                <img src={logo3} alt="Not Supported" className="img3" />
                <img src={logo4} alt="Not Supported" className="img3" />
                <img src={logo5} alt="Not Supported" className="img3" />
                <img src={logo6} alt="Not Supported" className="img3" />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row bd-highlight mb-3">
          <img src={logo7} alt="Not Supported" className="img1" />
        </div>
        <div className="row">
          <div className="col-1 hello"></div>
          <div className="first col-11 ">
            <p className=" fs-5  container parg">
              Installation and extension of wired and wireless Internet
              networks. We specialize in a vast range of connection in addition
              to equipment required.
            </p>
            <div className="">
              <div className="logos2 mb-3">
                <img src={logo8} alt="Not Supported" className="img3" />
                <img src={logo9} alt="Not Supported" className="img3" />
                <img src={logo10} alt="Not Supported" className="img3" />
                <img src={logo11} alt="Not Supported" className="img3" />
              </div>
            </div>
          </div>
        </div>
        <div className="space"></div>
      </div>
    </AnimationOnScroll>
  );
};

export default SixthHome;
